export const demoClientNames = [
	'James Adams',
	'Mark Irwin',
	'Thomas Klein',
	'Charles Trott',
	'Elizabeth Evans',
	'Laura Valdo',
	'Donald Klein'
]

export const demoClientEmails = [
	'matthew.clark@gmail.com',
	'aaronirwin57@yahoo.com',
	'fpzafar@gmail.com',
	'maryochoa@gmail.com',
	'brucexiang11@gmail.com',
	'yehuda.yakub@gmail.com',
	'martinmannafron@icloud.com',
	'susancrawford@gmail.com',
	'david.evans27@gmail.com'
]
