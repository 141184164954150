import React from 'react'

const ClientOnboarding = () => {
	return (
		<div className='NewForm NewFormWrapper'>
			<form onSubmit={null}>
				<div className='Title' style={{ textAlign: 'center' }}>
					Authorization Complete
				</div>
			</form>
		</div>
	)
}

export default ClientOnboarding
